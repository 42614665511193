import Swiper, {Navigation, Pagination, Autoplay} from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay]);

export default function sliders() {
    (function($) {
        $(window).on('load', function () {
           // get all sliders, we need to loop them due to different settings + nav
           var swipers = document.querySelectorAll('.reviews-swiper');
           swipers.forEach(function(el,index){
            var closestSection = el.closest('section');
            var controls = closestSection.querySelector('.control');

                // slider settings
                var options = {
                    speed: 600,
                    loop: true,
                    //autoplay: {
                        //delay: 5000,
                        //disableOnInteraction: true,
                    //},
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    pagination: {
                      el: ".swiper-pagination",
                  },
                  freeMode: true,
                  centeredSlides: true,
                  slidesPerView: 1,
                  spaceBetween: 10,

                  breakpoints: {
                      640: {
                        spaceBetween: 60,
                        slidesPerView: 1,
                    },
                    992: {
                        spaceBetween: 15,
                    },
                    1172: {
                        spaceBetween: 15,
                        slidesPerView: 1,
                    },
                },
            };

                // init slider
                new Swiper(el, options);
            });
       })

    })( jQuery );
}

